<template>
  <div class="activity-container">
    <!-- 分享按钮遮罩 -->
    <van-overlay :show="showMask" z-index="999" @click="showMask = false">
      <div class="wrapper">
        <div class="block">
          <div class="suduce-img">
            <van-image
              width="61"
              height="93"
              src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/img/seduce-img.png"
            ></van-image>
          </div>
          <div class="suduce-text">
            点击右上方按钮，在弹出的菜单中点击“发送给朋友”，即可进行分享
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- 分享按钮遮罩 -->
    <van-skeleton title :row="15" :loading="loading">
      <div class="title">{{ activity.title }}</div>
      <p class="sub-title">{{ activity.subTitle }}</p>
      <img class="event-bg" :src="activity.activityImg" alt="" />
      <!-- <div style="margin-top: 20px" v-html="activity.content"></div> -->
      <!-- 分享 -->
      <div class="share-box">
        <div class="share-to">分享至</div>
        <div class="invite-way">
          <div class="wx-share invite-item" @click="wxShare">
            <van-image
              width="36"
              height="36"
              src="https://img1.clozhome.com/app/wx.png"
            ></van-image>
            微信
          </div>
          <div
            class="wx-friend invite-item"
            @click="shareToCircleButton"
            v-if="!isMini"
          >
            <van-image
              width="36"
              height="36"
              src="https://img1.clozhome.com/app/wx_moment.png"
            ></van-image>
            朋友圈
          </div>
          <div class="poster invite-item" @click="generatePosterButton">
            <van-image
              width="36"
              height="36"
              src="https://img1.clozhome.com/app/photo.png"
            ></van-image>
            生成海报
          </div>
          <div class="poster invite-item" @click="moreShare" v-if="!isMini">
            <van-image
              width="36"
              height="36"
              src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/img/event-more.png"
            ></van-image>
            更多
          </div>
        </div>
        <div
          class="event-desc"
          v-if="activity.content.trim().length > 0"
          @click="eventDesc"
        >
          活动说明>
        </div>
      </div>
      <!-- 分享... -->
      <div style="margin-top: 30px">
        <div
          class="container"
          v-for="(item, index) in activity.activityHouseList"
          :key="'activityHouse' + index"
        >
          <div style="display: flex; justify-content: space-between">
            <span class="title">{{ item.title }}</span>
            <span class="more" @click="more(item)">查看更多 > </span>
          </div>
          <div class="sub-title">{{ item.subTitle }}</div>
          <div class="house-container">
            <div
              class="house-item"
              v-for="(hItem, idx) in item.houseList"
              :key="'house' + idx"
              @click="view(hItem)"
            >
              <van-image
                class="cover"
                radius="5"
                lazy-load
                :src="hItem.cover"
              />
              <p class="name">{{ hItem.areaName }} | {{ hItem.name }}</p>
              <div class="tag-p">
                <div
                  class="tag"
                  v-for="(tag, tIdx) in hItem.tagList"
                  :key="'tag' + tIdx"
                  :style="'background-color:' + tag.color"
                >
                  {{ tag.tag }}
                </div>
              </div>
              <div class="facilities-container">
                <!-- <div class="facilities">
                  <img class="icon" src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/img/bedroom.png" alt="" />
                  {{ hItem.bedroomNum }}
                  <img class="icon" src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/img/bathroom.png" alt="" />
                  {{ hItem.toiletNum }}
                  <img class="icon" src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/img/car.png" alt="" />
                  {{ hItem.parkNum }}
                </div> -->
                <div class="price">
                  <span>{{ hItem.rentPriceUnit }} {{ hItem.rentPrice }}</span>
                  <span>起{{ convertPriceCycle(hItem.rentPriceCycle) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-skeleton>
    <!-- canvas -->
    <div class="canvas-box">
      <div id="canvas" class="canvas">
        <div class="canvas-content">
          <div class="canvas-header">
            <div class="title">{{ activity.title }}</div>
            <p class="sub-title">{{ activity.subTitle }}</p>
            <img
              class="event-bg"
              :src="activity.activityImg"
              alt=""
            />
          </div>
          <div class="canvas-code">
            <img
              class="code-image"
              :src="rawQrCode"
            />
          </div>
          <div class="canvas-scan">扫一扫 赢奖励</div>
        </div>
      </div>
    </div>
    <!-- canvas... -->
  </div>
</template>

<script>
import {
  getActivityInfo,
  getActivityInfoBase64,
  getHouseListByIds,
} from "@/api/api";
import { convertPriceCycle, convertPriceUnit } from "@/utils/biz";
import { posterCode, setActivityPoster } from "@/api/service";
import html2canvas from "html2canvas";
// @ is an alias to /src
export default {
  name: "Activity",
  data() {
    return {
      showMask: false,
      loading: true,
      active: "0",
      activity: {
        title: "",
        subTitle: "",
        content: "",
        houseFlag: undefined,
        activityHouseList: [
          {
            houseId: "",
            title: "",
            subTitle: "",
            houseList: [{}],
          },
        ],
      },
      env: "", // 环境
      rawQrCode: "", //小程序码
      QrCode: "",
      image: "", //海报图
      activityId: "", //活动ID
      base64Canvas: "",
      tempList: [],
    };
  },
  computed: {
    isMini() {
      return this.$store.state.mini;
    },
  },

  created() {
    let activityId = this.$route.query.activityId;
    this.activityId = activityId;
    if (!activityId) {
      this.$failToast("参数错误");
      return;
    }
    getActivityInfo({
      activityId: activityId,
    }).then((res) => {
      let data = res.data;
      this.activity = data;
      data.activityHouseList.map((item) => {
        this.getHouseListById(item.houseIds).then(v=>{
          item.houseList = v
        })
      });
      document.title = "精彩活动";
      this.loading = false;
      var obj2 = JSON.stringify({
        title: data.title,
      });
      uni.postMessage({ data: obj2 });
    });
  },
  // 在使用到的页面 添加如下代码
  mounted() {
    console.log("activity == mounted结果===>");
    // 监听页面状态
    this.$nextTick(() => {
      this.getEnv();
    });
  },

  methods: {
    convertPriceCycle: convertPriceCycle,
    convertPriceUnit: convertPriceUnit,
    // 根据房源id获取房源列表
    getHouseListById(ids) {
      return new Promise((resolve, reject) => {
        getHouseListByIds({ houseIds: ids }).then((v) => {
          resolve(v.data)
        });
      });
    },
    getEnv() {
      let that = this;
      uni.getEnv(function (res) {
        console.log("getEnv结果===>", res);
        if (res.miniprogram) {
          that.$store.commit("SET_ENV", true);
          console.log("activity===this.isMini结果===>", that.isMini);
        }
      });
    },

    view(row) {
      if (this.isMini) {
        uni.navigateTo({
          url: "/housePages/houseDetail?id=" + row.id,
        });
      } else {
        enterHouseDetail.postMessage(
          JSON.stringify({
            houseType: row.rentType,
            houseId: row.id,
          })
        );
      }
    },
    more(row) {
      if (this.isMini) {
        uni.navigateTo({
          url: "/housePages/activity/houseList?houseIds=" + row.houseId,
        });
      } else {
        enterHotHouseList.postMessage(
          JSON.stringify({
            houseId: row.houseId,
            title: row.title,
          })
        );
      }
    },
    /**
     * 微信分享按钮
     */
    wxShare() {
      if (this.isMini) {
        this.showMask = true;
        setTimeout(() => {
          this.showMask = false;
        }, 2000);
      } else {
        shareMiniProgramToFriends.postMessage(
          JSON.stringify({
            thumnail: this.activity.activityImg,
            title: this.activity.title,
            miniPath: `/otherPages/webview/webviewInner??activityId=${this.activityId}`,
          })
        );
      }
    },
    /**
     * 生成海报
     */
    async generatePosterButton() {
      await this.generatePoster();
      console.log("生成海报结果===>", this.image);
      if (this.isMini) {
        const imgs = encodeURIComponent(this.image);
        uni.navigateTo({
          url:
            "/myPages/menu-list/invitation/register/poster?posterUrl=" + imgs,
        });
        //
      } else {
        enterGeneratePosterPage.postMessage(
          JSON.stringify({
            posterUrl: this.image,
          })
        );
      }

      console.log("canvas结果===>", this.image);
    },
    // 朋友圈分享
    async shareToCircleButton() {
      await this.generatePoster();
      sharePosterToCircle.postMessage(
        JSON.stringify({
          thumnail: this.image,
          title: this.activity.title,
          posterUrl: this.image,
        })
      );
    },
    // 朋友圈分享...
    // 更多
    async moreShare() {
      await this.generatePoster();
      showShareDialogs.postMessage(
        JSON.stringify({
          thumnail: this.image,
          title: this.activity.title,
          posterUrl: this.image,
          miniPath: `/otherPages/webview/webviewInner??activityId=${this.activityId}`,
          summary: this.activity.subTitle,
        })
      );
    },
    // 更多...
    /**
     * 活动说明
     */
    eventDesc() {
      this.$router.push({
        name: "ActivityContent",
        query: {
          activityId: this.$route.query.activityId,
        },
      });
    },
    // 获取小程序码
    async getWxCode(qrType, params) {
      const CodeObj = await posterCode(qrType, params);
      return (this.rawQrCode = CodeObj.data.url);
    },
    // 获取小程序码...

    // 生成海报
    async generatePoster() {
      if(this.activity.posterPath){
        return new Promise((resolve,rej)=>{
        this.image = this.activity.posterPath
          resolve()
        })
      }
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
      // await this.getBase64BG();
      window.scrollTo(0, 0);
      var that = this;
      await this.getWxCode("1", this.activityId);
      //两个参数：所需要截图的元素id，截图后要执行的函数， canvas为截图后返回的最后一个canvas
      let canvasRes = await html2canvas(document.getElementById("canvas"), {
        allowTaint: false, //允许污染
        taintTest: true, //在渲染前测试图片(没整明白有啥用)
        useCORS: true, //使用跨域(当allowTaint为true时这段代码没什么用,下面解释)
        // background: "#fff",
        scale: 2, // 处理模糊问题
        dpi: 300, // 处理模糊问题
      });

      const image = canvasRes.toDataURL();
      that.base64Canvas = image;
      const tempStr = image.split(",");
      const data = new FormData();
      data.append("base64Str", tempStr[1]);
      const imageUrl = await setActivityPoster({
        activityId:that.activityId,
        base64Str:tempStr[1]
      });
      that.image = imageUrl.data;
      console.log("imageUrl结果===>", imageUrl);
      this.$toast.clear();
      console.log("生成海报成功");
      // that.$successToast("生成海报成功");
    },
    // 生成海报...
    // 获取活动base64图片
    async getBase64BG() {
      const base64Data = await getActivityInfoBase64({
        activityId: this.activityId,
      });
      this.activity.activityImgBase64 = base64Data.data;
      console.log("获取活动base64图片结果===>");
    }
  },
};
</script>

<style lang="scss">
.canvas-box {
  position: fixed;
  top: 0;
  left: 0;
  left: 300%;
  // z-index: -1;
  // left: -9300px;
  .canvas {
    position: relative;
    overflow: hidden;
    width: 375px;
    height: 600px;
    background-color: #fff;
    .canvas-content {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 30px;
      position: relative;
      z-index: 2;
      .canvas-code {
        margin-top: 30px;
        width: 100%;
        height: 106px;
        display: flex;
        justify-content: center;
        .code-image {
          width: 106px;
          height: 106px;
        }
      }
      .canvas-scan {
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.wrapper {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  width: 100%;
  height: 100%;
  .block {
    padding: 20px 50px 0;
    // background-color: #fff;
    .suduce-img {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .suduce-text {
      text-align: center;
      margin-top: 20px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      line-height: 19px;
    }
  }
}

.activity-container {
  font-size: 14px;
  padding: 20px;

  .share-box {
    margin-top: 30px;
    .share-to {
      height: 14px;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
    }

    .invite-way {
      // width: 100%;
      height: 72px;
      // background: #fefefe;
      opacity: 0.8;
      border-radius: 8px;
      display: flex;
      justify-content: space-around;
      .invite-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 12px;
      }

      .wx-share {
        position: relative;
      }
    }
    .event-desc {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      font-size: 12px;
      font-weight: 500;
      color: #666666;
    }
  }

  .title {
    color: #333333;
    font-size: 18px;
    font-weight: bold;
  }

  .sub-title {
    margin-top: 18px;
    color: #666666;
    font-size: 14px;
  }

  .event-bg {
    margin-top: 20px;
    width: 100%;
  }

  .house-container {
    display: flex;
    overflow-x: auto;
  }

  .house-container::-webkit-scrollbar {
    display: none;
  }

  .container {
    margin-bottom: 20px;
    .sub-title {
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: 500;
    }
    .more {
      color: #666666;
      font-size: 12px;
    }

    .house-item {
      width: 240px;
      margin-right: 10px;
      color: #646060;
      .cover {
        width: 240px;
        height: 161px;
      }
      .name {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .tag-p {
        width: 100%;
        height: 30px;
        overflow: hidden !important;
      }
      .tag {
        // display: inline-block;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 1px;
        // min-width: 56px;
        height: 18px;
        text-align: center;
        color: #333;
        font-size: 11px;
        border-radius: 2px;
        margin: 5px 5px 2px 0px;
        // margin-right: 10px;
        padding: 2px;
        font-weight: 500;
        // overflow: hidden;
      }

      @media only screen and(max-width: 320px) {
        .tag:nth-child(3n + 0) {
          margin-right: 0px;
        }
      }
      @media only screen and(min-width: 400px) {
        .tag:nth-child(4n + 0) {
          margin-right: 0px;
        }
      }

      .facilities {
        font-size: 11px;
        color: #9e9d9d;
      }
      .icon {
        width: 12px;
        height: 9px;
      }

      .facilities-container {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
      }
      .price > span:nth-child(1) {
        color: #f67f58;
        font-size: 15px;
      }
      .price > span:nth-child(2) {
        color: #646060;
        font-size: 11px;
      }
    }
  }
}
</style>
