<template>
    <div class="sign-container">
        <div class="sign-left">
            <vue-esign ref="esign" class="canvas" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor"
                       bgColor="white"/>
        </div>
        <div class="btn-box">
            <p>
                <button class="btn submit" @click="handleGenerate">提&nbsp;&nbsp;交</button>
            </p>
            <p>
                <button class="btn clear" @click="handleReset">清&nbsp;&nbsp;空</button>
            </p>
        </div>
    </div>
</template>

<script>

    import vueEsign from 'vue-esign'

    import {getAgreementStatus, submitSignPic} from '@/api/agreement'

    export default {
        name: "signature",
        data() {
            return {
                lineWidth: 6,
                lineColor: '',
                isCrop: true,
                agreementId: undefined
            }
        },
        components: {
            vueEsign
        },
        beforeCreate() {		//在页面创建之前设置body的背景色
            document.querySelector('body').setAttribute('style', 'background:#F2F2F2')
        },
        beforeDestroy() {		//在页面销毁之前移除body的属性，目的是设置的颜色只对当前页面有效
            document.querySelector('body').removeAttribute('style')
        },
        created() {
            this.agreementId = this.$route.query.agreementId
            if (!this.agreementId) {
                this.$failToast('参数错误')
                return
            }
            getAgreementStatus({
                agreementId: this.agreementId
            }).then(res => {
                if (res.data.status == 1) {
                    //已经签名
                    this.$router.push({
                        path: 'agreement',
                        query: {
                            agreementId: this.agreementId
                        }
                    })
                    return
                }
            })

            this.$dialog.alert({
                title: '友情提醒',
                message: '请将您的设备横向放置进行签名，完成签名后点击“提交”',
                theme: 'round-button',
                confirmButtonText: '好的，我已了解',
                confirmButtonColor: '#F67F58'
            }).then(() => {
                // on close
            });
        },
        methods: {
            handleReset() {
                this.$refs.esign.reset()
            },
            handleGenerate() {
                this.$refs.esign.generate().then(res => {
                    this.rotateBase64Img(res, -90, (base64) => {
                        submitSignPic({
                            base64String: base64,
                            agreementId: this.agreementId
                        }).then(r => {
                            this.$router.push({
                                path: 'agreement',
                                query: {
                                    agreementId: this.agreementId
                                }
                            })
                        })
                    })
                }).catch(err => {
                    alert('请完成签字后进行提交!') // 画布没有签字时会执行这里 'Not Signned'
                })
            },
            rotateBase64Img(src, edg, callback) {
                var canvas = document.createElement("canvas");
                var ctx = canvas.getContext("2d");
                var imgW;//图片宽度
                var imgH;//图片高度
                var size;//canvas初始大小

                if (edg % 90 != 0) {
                    console.error("旋转角度必须是90的倍数!");
                    throw '旋转角度必须是90的倍数!';
                }
                (edg < 0) && (edg = (edg % 360) + 360)
                const quadrant = (edg / 90) % 4; //旋转象限
                const cutCoor = {sx: 0, sy: 0, ex: 0, ey: 0}; //裁剪坐标

                var image = new Image();
                image.crossOrigin = "anonymous"
                image.src = src;

                image.onload = function () {

                    imgW = image.width;
                    imgH = image.height;
                    size = imgW > imgH ? imgW : imgH;

                    canvas.width = size * 2;
                    canvas.height = size * 2;
                    switch (quadrant) {
                        case 0:
                            cutCoor.sx = size;
                            cutCoor.sy = size;
                            cutCoor.ex = size + imgW;
                            cutCoor.ey = size + imgH;
                            break;
                        case 1:
                            cutCoor.sx = size - imgH;
                            cutCoor.sy = size;
                            cutCoor.ex = size;
                            cutCoor.ey = size + imgW;
                            break;
                        case 2:
                            cutCoor.sx = size - imgW;
                            cutCoor.sy = size - imgH;
                            cutCoor.ex = size;
                            cutCoor.ey = size;
                            break;
                        case 3:
                            cutCoor.sx = size;
                            cutCoor.sy = size - imgW;
                            cutCoor.ex = size + imgH;
                            cutCoor.ey = size + imgW;
                            break;
                    }


                    ctx.translate(size, size);
                    ctx.rotate(edg * Math.PI / 180);
                    ctx.drawImage(image, 0, 0);

                    var imgData = ctx.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey);
                    if (quadrant % 2 == 0) {
                        canvas.width = imgW;
                        canvas.height = imgH;
                    } else {
                        canvas.width = imgH;
                        canvas.height = imgW;
                    }
                    ctx.putImageData(imgData, 0, 0);
                    callback(canvas.toDataURL())
                }
            }

        }
    };
</script>
<style lang="scss" scoped>
    .sign-container {
        padding: 20px;

        .canvas {
            background-color: white !important;
        }

        .btn-box {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;

            .btn {
                border-radius: 2px;
                padding: 5px 15px;
                font-size: 18px;
                transform: rotate(90deg);
            }

            .clear {
                background-color: #ffffff;
            }

            .submit {
                background: #A0D72C;
                color: #ffffff;
            }
        }
    }

</style>
