<template>
  <div class="sign-container">
    <div class="content" v-html="content"> </div>

    <div class="box">
      <p class="title">
        <img class="book-orange-icon" src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/img/agreement.png" alt="" />
        请勾选下列条款 <span class="orange span">(必选*)</span>
      </p>
      <div class="content">
        <p class="item" @click="checkChange(item, index)" v-for="(item, index) in agreements" :key="index">
          <van-checkbox :value="item.checked"
                        checked-color="#F57F57"
                        style="zoom: 80%">
            <span class="check-span">{{item.title}}</span>
          </van-checkbox>
        </p>
      </div>

    </div>

    <div class="box sign-box">
      <p class="title" style="margin-top: 40px;">
        <img class="book-orange-icon" src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/img/pencil.png" alt="" />
        您的确认签字 <span class="orange span">(必选*)</span>
      </p>
      <div class="content" @click="goSign">
        <img v-if="signImg"  class="sign-img" :src="signImg" alt="">
        <span v-else class="sign-btn">点击进行签名</span>
      </div>
    </div>

    <div style="display: flex;justify-content: center" v-if="status!=1">

      <van-button @click="submit" type="info"
                  :class="[!submitFlag?'btn-gray':'','btn']"
                  :loading="submitAction"
                  loading-text="加载中..." >立即提交</van-button>
    </div>

  </div>
</template>

<script >
  import {getAgreementStatus, addAgreementSign} from '@/api/agreement'
  export default {
    name:'Agreement',
    data() {
      return {
        id: undefined,
        content: '',
        agreements: [
          {title:'我已阅读此条款并熟悉条款内容'},
          {title:'我同意并愿意遵守此条款内容'}
        ],
        submitAction: false,
        signImg: '',
        agreementId: undefined,
        status: 0   //状态  0-未签署; 1-已经签署
      }
    },


    computed: {
      submitFlag() {
        let checkFlag = true
        for(let i=0; i<this.agreements.length; i++) {
          if (!this.agreements[i].checked) {
            checkFlag = false
            break
          }
        }
        return this.signImg && checkFlag
      }
    },

    created() {
      this.agreementId = this.$route.query.agreementId
      if (!this.agreementId) {
        this.$failToast('参数错误')
        return
      }
      getAgreementStatus({
        agreementId: this.agreementId
      }).then(res=>{
        let data = res.data
        this.content = data.content
        this.status = data.status
        if (data.signPic) {
          this.signImg = data.signPic
        }
        if (data.status == 1) {
          this.agreements.map(item=>{
            item.checked = true
          })
          this.$toast('已经签名')
        }
      })
    },

    methods: {
      checkChange(item, index) {
        if (this.status != 1) {
          //未签署选择
          item.checked = item.checked ? false : true
          this.$set(this.agreements, index, item)
        }
      },

      goSign() {
        if (this.status != 1) {
          this.$router.push({
            path: 'sign',
            query: {
              agreementId: this.agreementId
            }
          })
        }
      },

      submit() {
        if (this.submitFlag) {
          this.submitAction = true
          addAgreementSign({
            agreementId: this.agreementId
          }).then(r=>{
            this.$toast('签名完成')
            window.location.reload()
          }).catch(r=>{
            this.submitAction = false
          })
        }
      }

    }
  };
</script>

<style lang="scss">
  .sign-container {
    .van-checkbox {
      display: flex;
    }
  }

</style>

<style scoped lang="scss">
  .sign-container {
    font-size: 14px;
    color: #5F5F5F;
    padding: 20px;
    .orange {
      color: #F57F57;
    }
    .book-orange-icon, .pencil-orange-icon{
      width: 13px;
      height: 13px;
    }
    .title{
      font-weight: bold;
    }
      .box {
        margin-top: 20px;
        .item {
          margin-top: 10px;
        }
        .check-span{
          font-size: 18px;
          margin-left: 10px;
        }
    }
    .sign-box {
      .content{
        width: 335px;
        height: 80px;
        margin: 20px auto;
        border: 1px solid #9B9B9B;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .sign-img{
        width: 335px;
        height: 80px;
      }
      .sign-btn{
        padding: 10px 30px;
        border: 1px solid #9B9B9B;
        font-size: 18px;
        color: #6F6F6F;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .sign-btn:active{
        opacity: 0.8;
      }
    }

    .btn{
      border: none;
      width: 325px;
      height: 40px;
      margin-top: 20px;
      margin-bottom: 40px;
      color: #ffffff;
      background-color: #F57F57;
      font-weight: bold;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 5px;
    }
    .btn:active{
      opacity: 0.8;
    }

    .btn-gray{
      background-color: #9B9B9B;
    }

  }





</style>
