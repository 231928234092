<template>
    <div class="news-container app">
        <van-skeleton
                :row="20"
                :loading="loading"
        >
           <div class="content" v-html="showInfo"></div>
        </van-skeleton>
    </div>
</template>

<script>
    import { getSupplierFlowFileInfo } from  '@/api/relationMainBodyProcess'
    export default {
        name: "index",
        data(){
            return{
                id:'',
                loading:false,
                showInfo:{},
                token:''
            }
        },
        created() {
          this.init()
        },
        methods:{
            init(){
                console.log(this.$route.query)
                this.id = parseInt(this.$route.query.id)
                // this.token = this.$route.query.token
                // localStorage.setItem('token',this.token)
                if(this.id){
                    this.onLoad()
                }else{
                    this.$failToast('参数错误')
                }
            },
            onLoad(){
                this.loading = true
                getSupplierFlowFileInfo({
                    id:this.id}).then(res=>{
                    console.log('res.data', res.data);
                    this.showInfo = res.data.content
                    this.loading = false
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    @media screen and (min-width: 800px) {
        .news-container {
            box-sizing: border-box;
            padding: 30px;
            width: 100% !important;
            background-color: #f9f9f9 !important;
            /*margin-top:500px;*/
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%);
            max-width: 1200px;
        }
    }
    .app{
        padding: 10px;
        word-break: break-word;
    }
    .content{
        word-break: break-word;
    }
</style>
