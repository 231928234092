/**
 * 价格转换
 * @param rentPriceUnit
 * @returns {string}
 */
export function convertPriceUnit(rentPriceUnit) {
    switch (rentPriceUnit) {
        case 1: return 'CNY￥'
        case 2: return 'AU$'
        case 3: return '￡'
        case 4: return 'JPY￥'
    }
    return '异常'
}


/**
 * 租期转换
 * @param rentPriceCycle
 * @returns {string}
 */
export function convertPriceCycle(rentPriceCycle) {
    switch (rentPriceCycle) {
        case 1: return '每周'
        case 2: return '每月'
        case 3: return '每年'
    }
   return '异常'
}
