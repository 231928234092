
import request from '@/utils/request'

/**
 * 获取平台条款文档详情
 * @param query
 */
export function getAgreementStatus(query) {
    return request({
        url: '/order/apply/getAgreementStatus',
        method: 'get',
        params: query
    })
}

/**
 *  提交协议签名图片
 * @param query
 */
export function submitSignPic(data) {
    return request({
        url: '/order/apply/submitSignPic',
        method: 'post',
        data: data
    })
}

/**
 * 协议签订提交
 * @param data
 */
export function addAgreementSign(data) {
    return request({
        url: '/order/apply/addAgreementSign',
        method: 'post',
        data: data
    })
}

