import  request from  '@/utils/request.js'
let OMS_URL = process.env.VUE_APP_OMS_API

export function getSupplierFlowFileInfo(query) {
    return request({
        url: '/flow/file/getSupplierFlowFileInfo',
        method: 'get',
        baseURL:OMS_URL,
        params: query
    })
}
