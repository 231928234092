<template>
  <div class="news-container">
    <!-- 分享按钮遮罩 -->
    <van-overlay :show="showMask" z-index="999" @click="showMask = false">
      <div class="wrapper">
        <div class="block">
          <div class="suduce-img">
            <van-image
              width="61"
              height="93"
              src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/img/seduce-img.png"
            ></van-image>
          </div>
          <div class="suduce-text">
            点击右上方按钮，在弹出的菜单中点击“发送给朋友”，即可进行分享
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- 分享按钮遮罩 -->
    <van-skeleton title :row="15" :loading="loading">
      <div class="title">{{ news.title }}</div>
      <p class="sub-title" v-if="news.subTitle">{{ news.subTitle }}</p>
      <p class="sub-title" v-if="news.publishUserName">
        作者: {{ news.publishUserName }} {{ news.createTime }}
      </p>
      <div v-html="news.content"></div>
      <div class="share-box" v-if="false">
        <div class="share-to">分享至</div>
        <div class="invite-way">
          <div class="wx-share invite-item" @click="wxShare">
            <van-image
              width="36"
              height="36"
              src="https://img1.clozhome.com/app/wx.png"
            ></van-image>
            微信
          </div>
          <div
            class="wx-friend invite-item"
            @click="shareToCircleButton"
            v-if="!isMini"
          >
            <van-image
              width="36"
              height="36"
              src="https://img1.clozhome.com/app/wx_moment.png"
            ></van-image>
            朋友圈
          </div>
          <div
            class="wx-friend invite-item"
            @click="shareToQQ"
            v-if="!isMini"
          >
            <van-image
              width="36"
              height="36"
              src="https://img1.clozhome.com/new-clozhome/app/qq.png"
            ></van-image>
            QQ
          </div>
          <div
            class="wx-friend invite-item"
            @click="shareToQZone"
            v-if="!isMini"
          >
            <van-image
              width="36"
              height="36"
              src="https://img1.clozhome.com/new-clozhome/app/qzone.png"
            ></van-image>
            QQ空间
          </div>
        </div>
      </div>
    </van-skeleton>
  </div>
</template>

<script>
import { newDetail } from "@/api/api";

export default {
  name: "News",
  data() {
    return {
      showMask: false,
      loading: true,
      news: {
        title: "",
        subTitle: "",
        publishUserName: "",
        createTime: "",
        content: "",
      },
    };
  },
  created() {
    let newsId = this.$route.query.newsId;
    if (!newsId) {
      this.$failToast("参数错误");
      return;
    }
    newDetail({
      newsId: newsId,
    }).then((res) => {
      let data = res.data;
      this.news = data;
      document.title = data.title;
      this.loading = false;
    });
  },
  computed: {
    isMini() {
      return this.$store.state.mini;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getEnv();
    });
  },
  methods: {
    getEnv() {
      let that = this;
      uni.getEnv(function (res) {
        console.log("getEnv结果===>", res);
        if (res.miniprogram || res.h5) {
          that.$store.commit("SET_ENV", true);
          console.log("activity===this.isMini结果===>", that.isMini);
        }
      });
    },
    /**
     * 微信分享按钮
     */
    wxShare() {
      //
      if (this.isMini) {
        this.showMask = true;
        setTimeout(() => {
          this.showMask = false;
        }, 2000);
      } else {
        shareToWeixin.postMessage(
          JSON.stringify({
            // inviteCode: this.inviteCode,
            NewsPath: `/otherPages/webview/webviewInner?newsId=${this.newsId}`,
          })
        );
      }
      //
    },
    // 朋友圈分享
    async shareToCircleButton() {
      shareToCircle.postMessage(
        JSON.stringify({
          ActivityPosterUrl: this.image,
        })
      );
    },
    // 朋友圈分享...
    // QQ分享
    async shareToQQ() {
      shareToCircle.postMessage(
        JSON.stringify({
          ActivityPosterUrl: this.image,
        })
      );
    },
    // QQ分享...
    // QQ空间分享
    async shareToQZone() {
      shareToCircle.postMessage(
        JSON.stringify({
          ActivityPosterUrl: this.image,
        })
      );
    },
    // QQ空间分享...
  },
};
</script>

<style>
img {
  width: 100%;
}
</style>
<style lang="scss" scoped>
.news-container {
  padding: 10px;
  font-size: 20px;
  background-color: #ffffff;
  .title {
    color: #333333;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .sub-title {
    color: #666666;
    font-size: 13px;
    margin-bottom: 10px;
  }
}
.wrapper {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  width: 100%;
  height: 100%;
  .block {
    padding: 20px 50px 0;
    // background-color: #fff;
    .suduce-img {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .suduce-text {
      text-align: center;
      margin-top: 20px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      line-height: 19px;
    }
  }
}

.share-box {
  margin-top: 30px;
  .share-to {
    height: 14px;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
  }

  .invite-way {
    // width: 100%;
    height: 72px;
    // background: #fefefe;
    opacity: 0.8;
    border-radius: 8px;
    display: flex;
    justify-content: space-around;
    .invite-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }

    .wx-share {
      position: relative;
    }
  }
  .event-desc {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    color: #666666;
  }
}
</style>
