<template>
  <div class="about">
    <div
      v-html="content"
      :class="key === 'for_me' ? 'FontBold' : ''"
      style="font-size: 14px"
    ></div>
  </div>
</template>

<script>
/**
 * 获取平台条款文档详情 通用页面
 */

import { getArticleInfo } from "@/api/api";

export default {
  name: "PlatArticle",
  data() {
    return {
      content: "",
      key: "",
    };
  },
  created() {
    let key = this.$route.query.key || "for_me";
    this.key = this.$route.query.key || "for_me";

    getArticleInfo({
      key: key,
    }).then((res) => {
      this.content = res.data.content;
    });
  },
  methods: {},
};
</script>

<style>
img {
  width: 100%;
}
</style>
<style lang="scss" scoped>
.FontBold {
  font-size: 18px !important;
}
.about {
  font-size: 20px;
  padding: 10px;
}
</style>
