<template>
  <div class="news-container">
    <van-skeleton title :row="15" :loading="loading">
      <div class="title">{{notice.title}}</div>
      <p class="sub-title">{{notice.createDate}}</p>
      <div v-html="notice.content"></div>
    </van-skeleton>
  </div>
</template>

<script>
  import { getNotice } from '@/api/api'

  export default {
    name: 'Notice',
    data() {
      return {
        loading: true,
        notice: {
          title: '', createDate: '', content: ''
        }
      }
    },
    created() {
      let noticeId = this.$route.query.noticeId
      if (!noticeId) {
        this.$failToast('参数错误')
        return
      }
      getNotice({
        noticeId: noticeId
      }).then(res => {
        let data = res.data
        this.notice = data
        document.title = data.title
        this.loading = false
      })
    },
    methods: {}
  }
</script>

<style>
  img{
    width: 100%;
  }
</style>
<style lang="scss" scoped>

  .news-container {
    padding: 10px;
    font-size: 20px;

    .title {
      color: #333333;
      font-size: 17px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .sub-title {
      color: #666666;
      font-size: 13px;
      margin-bottom: 10px;
    }
  }
</style>
